@import '../index.scss';

.fil_ariane {
  display: flex;
  text-align: start;
  padding: 20px 30px;
  margin: 20px;
}

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
  
.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 5px;
}
  
.breadcrumb-item a {
  text-decoration: none;
  font-weight: 600;
}
  
.breadcrumb-item a:hover {
  color: $primary-color;
  text-decoration: underline;
}
  
.breadcrumb-item.active {
  color: $text-color-gray;
}

@media screen and (max-width: 900px) {
  .fil_ariane {
    padding: 10px 15px;
    margin: 10px;
  }
}
