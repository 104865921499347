@import '../index.scss';

.titleContact {
    display: none;
}

.contact_page {
    display: grid;
    background: $background-color;
    gap: 20px;

    .contactANDform {
        display: grid;
        grid-template-columns: 1fr 2fr;
        justify-content: space-between;
        gap: 20px;
        padding: 5%;

        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr;
            padding: 3%;
            
        }

        .contact {
            display: grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            gap: 20px;
        
            .infos_contact {
                display: grid;
                grid-template-rows: 1fr;
                gap: 20px;
                justify-content: start;
                width: 80%;

                a {
                    display: flex;
                    justify-content: center;
                }

                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
            }
        }

        .formulaire {
            display: grid;
            gap: 20px;

            h2 {
                padding: 3%;
            }
        }
    }

    .map {
        display: grid;
        width: 100%;
        background: $background-color;
    }
}


