@import '../index.scss';

.blog {
    padding: 10% 2%;

    h1 {
        color: $primary-color;
        margin-bottom: 20px;
        text-shadow: none;
    }

    p {
        margin-bottom: 30px;
    }

    .random-image {
        margin: 50px 2%;

        img {
            border-radius: 10px;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
            max-width: 100%;
            height: auto;
        }
    }
}