@import '../index.scss';

.cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $secondary-color;
    padding: 10px;
    text-align: center;
    z-index: 9999;

    button {
        background-color: $primary-color;
        color: $text-color-light;
        border: none;
        padding: 10px 15px;
        border-radius: 5px;
        margin: 15px;
        cursor: pointer;

        &:hover {
            background-color: $background-color-light;
            color: $text-color-dark;
        }
    }
}