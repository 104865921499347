.form_register {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 200px 40px;
    gap: 20px;
    
    input {
      width: 300px;
      height: 40px;
      margin: 10px;
      padding: 10px;
    }
}
