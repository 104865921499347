@import '../index.scss';

.BtnRdv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  background-color: $primary-color;
  color: $text-color-light;
  border: none;
  border-radius: 30px;
  padding: 20px 30px;
  margin: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: $secondary-color;
    color: $text-color-dark;
    box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
  }
}