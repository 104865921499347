@import '../index.scss';

.faq {
    padding: 20px;
    line-height: 1.6;
    padding: 0 5%;

    h1 {
        color: $primary-color;
        margin-bottom: 20px;
    }

    .container-sections {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        section {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: start;
            margin-bottom: 40px;
    
            h2 {
                color: $primary-color;
                margin-bottom: 15px;
            }
    
            .question {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: start;
                margin-bottom: 20px;
    
                h3 {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    text-align: start;
                    font-size: 1.2rem;
                    color: $text-color-dark;
                    margin-bottom: 5px;
                }
    
                p {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    text-align: start;
                    font-size: 1rem;
                    color: $text-color-gray;
                }
            }
        }
    }
}