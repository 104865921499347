@import '../index.scss';

.services {
    background: $background-color;
}

.container-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 5%;
    height: 100vh;
}

.card {
    perspective: 1000px; /* Effet de flip */
}

.card-over {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1.5s; /* 1.5 secondes de vitesse de transition */
    transform-style: preserve-3d;
}

.card:hover .card-over {
    transform: rotateY(180deg);
}

.card-recto,
.card-verso {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: start;
    background: $background-color-light;
    border: 2px solid $primary-color;
    
}

.card-recto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-recto img {
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.card-verso {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: rotateY(180deg);
    overflow: auto; 

    .text {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        height: 100%;
        padding: 20px;
    }
}

@media screen and (max-width: 900px) {
    .container-cards {
        grid-template-columns: 1fr;

        .card-recto {
            img {
                height: 100px;
            }

            h2 {
                font-size: 1.2em;
            }
        }

        .card-verso {
            .text {
                display: flex;
                flex-direction: column;
                justify-content: start;
            }
        }
    }
}