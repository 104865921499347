.not-found {
   display: flex;
   justify-content: center;
   width: 100%;
   height: 100vh;

   div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px;
    gap: 30px;
    
   }
}