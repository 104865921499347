@import '../index.scss';

.appointment {
    display: grid;
    align-items: center;
    text-align: center;
    background: $background-color;
    padding: 10% 2%;
    gap: 20px;
  

    h1 {
        display: none;
    }

    .recap {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 20px;
    }

    .BtnContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
    
        button {
            display: flex;
            background-color: $primary-color;
            color: $text-color-light;
            border: none;
            padding: 20px 30px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
            transition: all 0.3s ease;
            &:hover {
                background-color: $secondary-color;
                color: $text-color-dark;   
            }
        }
    }

    a {
        display: flex;
        justify-content: center;
       
        .BtnContact {
            display: flex;
            justify-content: center;
            align-items: center;
            text-wrap: nowrap;
            background-color: $primary-color;
            color: $text-color-light;
            border: none;
            border-radius: 30px;
            padding: 20px 30px;
            margin: 20px;
            cursor: pointer;
            transition: all 0.3s ease;
            
            &:hover {
                background-color: $secondary-color;
                color: $text-color-dark;
                box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
            } 
        }
    }
} 
 
@media screen and (max-width: 900px) {
    .appointment {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: $background-color;
        padding: 3%;
        gap: 20px;
        width: 100%;
    
        h1 {
            display: block;
        }

        .BtnContainer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 10px;

            button {
                padding: 10px 20px;
            }
        }
    }
    
}
