@import '../index.scss';

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/kine21.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
    padding: 2%;
    
    h1 {
        display: flex;
        align-items: center;
        color: $text-color-light; 
        max-width: 90%;

        &:hover {
            transform: scale(1.2);
            transition: 2s;
        } 
    }

    @media screen and (max-width: 900px) {
        height: 300px;
    }
}

.presentation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    width: 100%;
    background: $background-color;
    padding: 2% 5%;
    gap: 20px;

    .text {
        display: grid;
        gap: 20px;
        
        p {
            text-align: justify;  
        }       
    }

    img {
        height: 300px;
        width: 300px; 
        object-fit: cover;
    }

    @media screen and (max-width: 900px) {

        grid-template-columns: 1fr;
    }
}

.servicesHome {
    display: grid;
    align-items: center;
    background: $background-color-light;
    margin-top: 2%;
    gap: 20px;

    .services-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
        align-items: center;  
    }

    .post-operatoire, .traitement-douleur, .kine-sports, .balneotherapie {
        display: grid;
        justify-items: center;
        height: 300px;
        padding: 10%;
        background: $background-color;
        transition: background 0.3s ease;
        
        &:hover {
           background: $primary-color;
           color: $text-color-light;
        }
    }

    @media screen and (max-width: 900px) {

        .services-container {
            grid-template-columns: 1fr;
        }

        .post-operatoire, .traitement-douleur, .kine-sports, .balneotherapie {
            height: auto;

            &:hover {
                background: $primary-color;
                color: $text-color-light;
            }  
        }
    }
}

.bienvenueHome {
    display: grid;
    align-items: center;
    background: $background-color-light;
    margin-top: 2%;
    gap: 20px;
    
    .bienvenue-container {
        background: $background-color;
    
        p {
            text-align: justify;
            padding: 5%;
        }
    }
}

.img-cover {
   background-image: url("../assets/piscineBalneo1.jpg");
   background-size: cover;
   background-position: bottom 20% center;
   background-repeat: no-repeat;
   height: 600px;
   width: 100%;

   @media screen and (max-width: 900px) {
       height: 300px;
   }
}