@import '../index.scss';

.footer { 
    display: grid;
    width: 100%;
    box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
    
    .logo { 
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        max-height: 100%;
    }
}

.footer-container {
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    text-align: center;
    background: $background-color-light;
    padding: 2%;
    gap: 20px;

    .infos_footer {
        display: grid;
        gap: 20px;
    
        img {
            height: 30px;
        }
    }

    .mentions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
}

@media screen and (min-width: 900px) {

    .footer {
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }
    .infos_footer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        justify-content: space-between;
        align-items: center;
    }

}