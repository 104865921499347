@import '../index.scss';

.cardUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: $background-color;
    padding: 2%;
    gap: 10px;

    .list_appointments {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .rdv {
            list-style: none;
            text-align: left;

            .btnBin, .btnEdit {
                background-color: transparent;
                border: none;
                cursor: pointer;
                margin-right: 10px;

                .imgBin, .imgEdit {
                    width: 20px;
                    height: 20px;
                }

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .btnContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
       
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            text-wrap: nowrap;
            background-color: $primary-color;
            color: $text-color-light;
            border: none;
            border-radius: 30px;
            padding: 20px 30px;
            margin: 10px;
            cursor: pointer;
            transition: all 0.3s ease;
        
            &:hover {
            background-color: $secondary-color;
            color: $text-color-dark;
            box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .editAppointmentForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: $secondary-color;
        border-radius: 10px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
        padding: 5%;
        margin: 5%;
        gap: 20px;

        .formEdit  {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 10px;

            .dateSelection, .timeSelection {
                display: flex;
                flex-direction: column;
                gap: 10px;

                select {
                    padding: 10px;
                    border-radius: 5px;
                    border: none;
                    
                }
            }
        }

        .btnContainer {
            display: flex;
            flex-direction: column;
            
            button {
                &:hover {
                    background-color: $background-color;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .cardUser {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: $background-color;
        padding: 3%;
        gap: 20px;
        
        .btnContainer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 10px;

            button {
                padding: 10px 20px;
            }
        }
    }
}