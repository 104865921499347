@import '../index.scss';

.privacy-policy {
    padding: 20px;
    line-height: 1.6;
    padding: 0 5%;

    h1, h2 {
        color: $primary-color;
        margin-bottom: 20px;
    }

    section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: start;
        margin-bottom: 40px;

        p {
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: start;
            font-size: 1rem;
            color: $text-color-gray;
        }
    }
}