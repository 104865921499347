.form_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20% 2%;
    gap: 20px;

    
    input {
      width: 300px;
      height: 40px;
      margin: 10px;
      padding: 10px;
    }
}   