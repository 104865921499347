@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Quintessential&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Inter';
  font-size: 1rem;

  @media screen and (max-width: 900px) {
    font-size: 0.9rem;
  }
}

// Variables de couleurs
$primary-color: #177795;
$secondary-color: #93ccdf;
$background-color: #F5E5CB;
$background-color-light: white;
$text-color-dark: black;
$text-color-light: white;
$text-color-gray: #666;