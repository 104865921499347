@import './index.scss';

h1 {
  font-family: "Quintessential", cursive;
  font-size: 3rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

h2 {
  font-family: "Quintessential", cursive;
  font-size: 2.5rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

h3 {
  font-family: "Quintessential", cursive;
  font-size: 1.9rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

h4 {
  font-family: "Quintessential", cursive;
  font-size: 1.5rem;
}

h5 {
  font-family: "Quintessential", cursive;
  font-size: 1.2rem;
  font-weight: 500;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: black;
  font-size: 1rem;

  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}

@media screen and (max-width: 900px) {

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1rem;
  }

  p {
    font-size: 0.9rem;
  } 

  a {
    font-size: 0.9rem;
  }
  
}