@import '../index.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 150px;
    position: sticky;
    top: 0;
    z-index: 999;
    background: $background-color-light;
    box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
    padding: 0 50px;

    .logo img {
        height: 100px;
        max-height: 100%;
    }

    .menu-list {
        ul {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin: 0 15px;
            }

            a {
                text-decoration: none;
                font-size: 1.2rem;
                color: $text-color-dark;
                
                &:hover {
                    color: $primary-color;
                    text-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
                }

                img {
                    display: none;
                    width: 40px;
                }
            }
        }
    }

    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .icon-user-desktop {
            width: 40px;
        }
    }
}

.menu-mobile {
    display: none;

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

@media screen and (max-width: 900px) {
    .header {
        height: auto;
        padding: 10px 20px;
    }

    .header-container {
        flex-direction: row;
        align-items: start;

        .icon-user-desktop {
            display: none;
        }  
    }

    .menu-list {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 75%;  
        background: $background-color-light;
        box-shadow: -1px 0 5px rgba(0, 0, 0, 0.2);
        transform: translateX(100%);  
        transition: transform 0.3s ease-in-out;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 50px 20px;
            margin: 0;
            font-size: 1.5rem;
            gap: 10px;

            li {
                a {
                    .icon-user-mobile {
                        display: block;
                    }
                }
            } 
        }
    }

    .menu-mobile {
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
        height: 80px;
        background: $background-color-light;
        padding-inline: 20px;
        z-index: 10000;
    }

    .menu-open .menu-list {
        transform: translateX(0);
    }

    .menu-open .burger {
        display: none;
    }

    .menu-open .cross {
        display: block;
        position: fixed;
        top: 50px;
        right: 50px;
    }
}