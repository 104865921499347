@import '../index.scss';

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: $background-color;
    padding: 2%;
    gap: 20px;

    .about_p{
        display: flex;
        text-align: justify;
        padding: 20px;
    }

    h4{
        padding: 20px;
    }

    .slider-container {
        max-width: 500px;
        margin: 20px auto;
        overflow: hidden;

        @media screen and (max-width: 900px) {
            max-width: 300px;
            
        }
    }

    .slider-container .slider {
        display: flex;
        animation: slider 10s infinite ease-in-out;
    }

    .slider img {
        flex-shrink: 0; // Qui veut dire si tu dois dépasser je t'autorise à dépasser de ton parent
        width: 100%;
    }

    ul {
        padding: 20px;
    
        li {
            list-style: none;
            text-align: start;
            padding: 10px;

            span {
                font-weight: bold;
            }
        }
    }

    .BtnContact {
        display: flex;
        justify-content: center;
        align-items: center;
        text-wrap: nowrap;
        background-color: $primary-color;
        color: $text-color-light;
        border: none;
        border-radius: 30px;
        padding: 20px 30px;
        margin: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        
        &:hover {
            background-color: $secondary-color;
            color: $text-color-dark;
            box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        } 
    }
}

@keyframes slider {
    0%, 20% {
        transform: translateX(0);
    }
    25%, 45% {
        transform: translateX(-100%);
    }
    50%, 70% {
        transform: translateX(-200%);
    }
    75%, 95% {
        transform: translateX(-300%);
    }
    100% {
        transform: translateX(-400%);
    }
}