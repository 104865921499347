@import '../index.scss';

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 20px;

    input, textarea, select {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border: 2px solid $primary-color;
        box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        max-width: 50vw;

        @media screen and (max-width: 900px) {
            max-width: 80%;
        }
    }

    textarea {
        resize: none;
        height: 200px;
    }

    button[type="submit"] {
        
        display: flex;
        justify-content: center;
        align-items: center;
        text-wrap: nowrap;
        background-color: $primary-color;
        color: $text-color-light;   
        border: none;
        border-radius: 30px;
        padding: 20px 30px;
        cursor: pointer;
        transition: all 0.3s ease;
        
        &:hover {
            background-color: $secondary-color;
            color: $text-color-dark;
            box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.2);
        }
    }
}